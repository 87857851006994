<div class="document-table-expansion-details">
  <div class="expansion-details-list-header">
    <div class="document-id">{{documentHeader.documentId}}</div>
  </div>
  <mat-grid-list cols="2" rowHeight="200px">
    <mat-grid-tile>
      <mat-list>
        <mat-list-item>
          <mat-icon matListItemIcon
                    class="icon">{{type === 'settings' ? 'pending' : documentHeader.status.icon}}</mat-icon>
          <span matListItemTitle>Status</span>
          <span matListItemLine>{{type === 'settings' ? 'Ausstehend' : documentHeader.status.description}}</span>
        </mat-list-item>
        <app-divider></app-divider>
        <mat-list-item>
          <mat-icon matListItemIcon class="icon">sync_alt</mat-icon>
          <span matListItemTitle>Veröffentlichung</span>
          <span
            matListItemLine>{{documentHeader.published ? (documentHeader.published | date: dateFormat) : type === 'settings' ? 'Jetzt' : 'Nicht veröffentlicht' }}
            <span
              *ngIf="documentHeader.unpublished"> bis <br>{{documentHeader.unpublished | date: dateFormat}}</span></span>
        </mat-list-item>
        <app-divider class="divider"></app-divider>
      </mat-list>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-list class="expansion-details-list">
        <mat-list-item>
          <mat-icon matListItemIcon class="icon">update</mat-icon>
          <span matListItemTitle>Letzte Änderung</span>
          <span matListItemLine>{{documentHeader.updated | date: dateFormat}}</span>
        </mat-list-item>
        <app-divider></app-divider>
        <mat-list-item *ngIf="documentHeader.workflow">
          <mat-icon matListItemIcon class="icon">{{documentHeader.workflow.icon}}</mat-icon>
          <span matListItemTitle>Workflow/Inhalt</span>
          <span style="word-wrap: anywhere" matListItemLine>{{documentHeader.workflow.description}}</span>
        </mat-list-item>
        <app-divider></app-divider>
      </mat-list>
    </mat-grid-tile>
  </mat-grid-list>

  <!--  <div class="expansion-details-list-actions">-->
  <!--    <button *ngIf="document.status.code === statuscodes.PUBLISH"-->
  <!--            matTooltip="Daten in neues Dokument übertragen" matRipple-->
  <!--            class="mat-elevation-z0"-->
  <!--            mat-icon-button>-->
  <!--      <mat-icon color="primary">post_add</mat-icon>-->
  <!--    </button>-->
  <!--  </div>-->

</div>
