import {Component, Input} from '@angular/core';
import {DocumentHeader} from "../../../../model/document.model";
import {DatePipe, NgIf} from '@angular/common';
import {DividerComponent} from '../../../common/divider/divider.component';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';

@Component({
  selector: 'app-document-table-expansion-detail',
  templateUrl: './document-table-expansion-detail.component.html',
  styleUrls: ['./document-table-expansion-detail.component.scss'],
  standalone: true,
  imports: [MatGridListModule, MatListModule, MatIconModule, DividerComponent, NgIf, DatePipe]
})
export class DocumentTableExpansionDetailComponent {

  dateFormat: string = 'dd.MM.yy HH:mm'
  @Input() documentHeader!: DocumentHeader;

  @Input() type: 'summary' | 'settings' = 'summary';
}
